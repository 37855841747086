@import 'colors';
@import 'media-vars';
body{
  font-family: 'Roboto', sans-serif;
  color: $col-black;
  background-color: #000;
  font-weight: 600;
  overflow-x: hidden;
  height: 100vh;


  &.cookies{
    background-image: url('../images/1920-ciastka.jpg');
    background-color: $col-background;
    background-position: center top;
    @media(max-width: 800px){
      background-size: 150% auto;
    }
    @media(max-width: 400px){
      background-size: 300% auto;
    }
    overflow: auto;
    background-repeat:no-repeat;
    h1{
      padding-top: 250px;
      @media(max-width: 400px){
        padding-top: 200px;
      }
    }
  }
  &.error-404{
    background-image: url('../images/1920-error.jpg');
    background-color: $col-background;
    background-position: center top;
    @media(max-width: 800px){
      background-size: 150% auto;
    }
    @media(max-width: 400px){
      background-size: 300% auto;
    }
    overflow: auto;
    background-repeat:no-repeat;
    h1{
      padding-top: 250px;
      @media(max-width: 400px){
        padding-top: 200px;
      }
    }
  }
  @media(max-height: 550px){
    overflow: auto;
    overflow-x: hidden;
  }
  @media(max-width: 400px){
    width: 100%;
    min-height: 550px;
    overflow-x: hidden;
  }
}

.home{
  background-image: url('../images/1920-background.jpg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: #e9e8e8;
  background-attachment: fixed;
  @media(max-width: 1024px){
    background-position: left -100px top;
  }
  @media(max-width: 950px){
    background-image: none;
    background-attachment: inherit;
    background-position: bottom;
    background-color: #e9e8e8;
  }
  #content{
    text-align: center;
    width: 70%;
    position: absolute;
    right: 0px;
    @media(max-width: 1200px){
      width: 50%;
    }
    @media(max-width: 950px){
      width: 100%;
      padding-bottom: 840px;
      background-image: url('../images/950-background.jpg');
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: bottom;
      background-color: #e9e8e8;
    }
    @media(max-width: 800px){
      padding-bottom: 740px;
    }
    @media(max-width: 600px){
      padding-bottom: 540px;
    }
    @media(max-width: 420px){
      padding-bottom: 400px;
    }
    @media(max-width: 375px){
      padding-bottom: 350px;
    }
    header{
      text-align: center;
      h1{
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
        color: $col-black;
        @media(max-width: 420px){
          font-size: 16px;
          span{
            display: block;
          }
        }
      }
      img{
        width: 600px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
        @media(max-width: 1366px){
          width: 400px;
        }
        @media(max-width: 420px){
          width: 300px;
        }
        @media(max-width: 350px){
          width: 280px;
        }
      }
    }

      #zwiastun{
        position: relative;
        margin-left: auto;
        margin-right: auto;
        width: 527px;
        height: 310px;
        @media(max-width: 1366px){
          width: 400px;
          height: 235px;
        }
        @media(max-width: 420px){
          width: 320px;
          height: 188px;
        }
        border: 1px solid #fff;
        overflow: hidden;
        margin-top: 20px;
        &:hover{
          #btn-play{
            img{
              transform: scale(1.2,1.2);
            }
          }
        }
        #btn-play{
          position: absolute;
          left: 50%;
          top:50%;
          z-index: 10;
          transform: translate(-50%,-50%);
          img{
            transition: transform 0.5s;
            @media(max-width: 1366px){
              width: 100px;
              height: auto;
            }
          }
        }
        video{
          position: absolute;
          left: 0;
          width: 100%;
          height: auto;
          top:0;
          z-index: 2;
        }
        #img-trailer{
          position: absolute;
          left: 0;
          top:0;
          width: 100%;
          height: auto;
        }
      }
      #link-wybierz-seans{
        width: 326px;
        height: 69px;
        @media(max-width: 1366px){
          width: 290px;
          height: 60px;
        }
        @media(max-width: 420px){
          width: 250px;
          height: 53px;
        }
        display: block;
        margin-left: auto;
        margin-right: auto;
        &:hover{
          #btn-wybierz-seans{
            transform: scale(0.8,0.8);
          }
        }
        #btn-wybierz-seans{
          transition: transform 0.5s;
          margin-top: 30px;
          width: 100%;
          height: auto;
        }
      }
      #link-wybierz-seans-zagranica{
        display: block;
        color: $col-black;
        text-decoration: underline;
        margin-top: 60px;
        transition: color 0.5s;
        &:hover{
          color:$col-red;
        }
      }
      .subscribe{
        margin-top: 20px;
        padding-bottom: 30px;
        p{
          font-size: 20px;
          font-weight: 200;
          @media(max-width: 1366px){
            font-size: 14px;
          }
        }
        ul{
          margin-top: 20px;
          li{
            display: inline-block;
            position: relative;
            margin-left: 5px;
            margin-right: 5px;
            &:hover{
              img{
                &.hover{
                  opacity: 1;
                }
              }
            }
            img{
              @media(max-width: 1366px){
                height: 30px;
                width: auto;
              }
              &.hover{
                position: absolute;
                left: 0;
                top:0;
                opacity: 0;
                transition: opacity 1s;
              }
            }
          }
        }
      }
      footer{
      padding-top: 30px;
      padding-bottom: 30px;
      @media(max-width: 950px){
        padding-top: 0;
        padding-bottom: 60px;
      }
      ul{
        text-align: center;
        li{
          display: inline-block;
          margin-left: 20px;
          margin-right: 20px;
          @media(max-width: 400px){
            margin-left: 10px;
            margin-right: 10px;
          }
          @media(max-width: 350px){
            margin-bottom: 20px;
          }
          img{
            height: 60px;
            width: auto;
            @media(max-width: 1366px){
              height: 40px;
              width: auto;
            }

          }
        }
      }
    }
  }

}
.repertuar{
  background-image: url('../images/1920-background.jpg');
  background-size: auto 100%;
  background-repeat: no-repeat;
  background-color: #e9e8e8;
  background-attachment: fixed;
  @media(max-width: 1024px){
    background-position: left -150px top;
  }
  @media(max-width: 950px){
    background-image: none;
    background-attachment: inherit;
    background-position: bottom;
    background-color: #e9e8e8;
  }
  .content{
      text-align: center;
      width: 60%;
      position: absolute;
      right:5%;
      @media(max-width: 1680px){
        right: 2%;
        width: 50%;
      }
      @media(max-width: 1366px){
        right: 2%;
        width: 60%;
      }
      @media(max-width: 1200px){
        width: 55%;
      }
      @media(max-width: 950px){
        width: 100%;
        right: 0%;
        padding-bottom: 840px;
        background-image: url('../images/950-background.jpg');
        background-size: 100% auto;
        background-repeat: no-repeat;
        background-position: bottom;
        background-color: #e9e8e8;
      }
      @media(max-width: 800px){
        padding-bottom: 740px;
      }
      @media(max-width: 600px){
        padding-bottom: 540px;
      }
      @media(max-width: 420px){
        padding-bottom: 400px;
      }
      @media(max-width: 375px){
        padding-bottom: 350px;
      }
    header{
      text-align: center;
      h1{
        font-weight: 700;
        text-transform: uppercase;
        font-size: 20px;
        color: $col-black;
        @media(max-width: 420px){
          font-size: 16px;
          span{
            display: block;
          }
        }
      }
      img{
        width: 600px;
        height: auto;
        margin-left: auto;
        margin-right: auto;
        display: block;
        margin-top: 30px;
        margin-bottom: 30px;
        @media(max-width: 1366px){
          width: 400px;
        }
        @media(max-width: 420px){
          width: 300px;
        }
        @media(max-width: 350px){
          width: 280px;
        }
      }
    }
    h2{
      &.country{
        text-transform: uppercase;
        margin-top: 80px;
      }
    }
    h3{
      font-size: 13px;
      text-align: center;
      padding-bottom: 20px;
      &#filtr{
        margin-top: 30px;
        padding-bottom: 0;
      }
    }

    nav{
      &#date_and_city{
        ul{
        width: 100%;
        line-height: 0;
        @media(max-width: 650px){
          width: 90%;
          margin-left: 5%;
        }
          li{
            width: 49%;
            display: inline-block;
            line-height: 16px;
            background-repeat: no-repeat;
            @media(max-width: 650px){
              width: 100%;
              display: block;
              margin-bottom: 10px;
            }
            &.day{
              background-image: url('../images/ikona-kalendarz.png');
              background-size: 40px;
              background-position: left 10px top 5px;
              @media(max-width: 1366px){
                background-size: 28px;
              }

            }
            &.city{
              background-image: url('../images/ikona-miejsce.png');
              background-size: 40px;
              background-position: left 10px top 5px;
              @media(max-width: 1366px){
                background-size: 28px;
              }
            }
            .select2-container{
              width: 88% !important;
              margin-left: 11%;
              margin-right: inherit;
              @media(max-width: 1680px){
                width: 80% !important;;
                margin-left: 19%;
              }
              @media(max-width: 1366px){
                width: 84% !important;;
                margin-left: 15%;
              }

              .select2-selection{
                padding:10px;
                height: auto;
                vertical-align: middle;
                .select2-selection__arrow{
                  top:11px;
                }
              }
            }

            select{
              padding: 20px;
              background-color: #fff;
              border: 1px solid $col-grey;
              border-radius: 10px;
              width: 85%;
              margin-left: 14%;
              // background-image: url('../images/arrow-down.png');
              background-repeat: no-repeat;
              text-align: center;
              text-align-last:center;
              background-position: right 15px top 20px;
              @media(max-width: 1366px){
                padding: 10px;
                background-position: right 15px top 15px;
                background-size: 15px;
              }
            }
          }
        }
      }
      &#cinemas{
        ul{
        width: 100%;
        line-height: 0;
        margin-top: 0px;
        margin-bottom: 30px;
          li{
            width: 24%;
            display: inline-block;
            line-height: 16px;
            text-align: center;
            @media(max-width: 450px){
              width: 49%;
            }
            span{
              display: inline-block;
              padding: 20px;
              color: $col-black;
              text-decoration: none;
              text-transform: uppercase;
              font-size: 20px;
              cursor: pointer;
              transition: color 0.5s;
              @media(max-width: 1200px){
                font-size: 16px;
                vertical-align: middle;
              }
              &.active{
                color: $col-red;
              }
              &:hover{
                color: $col-red;
              }
            }
          }
        }
      }
    }
    table{
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      border-radius: 10px;
      overflow: hidden;
      margin-top: 50px;
      @media(max-width: 1680px){
        width: 100%;
      }
      @media(max-width: 13660px){
        width: 80%;
        margin-top: 20px;
      }
      @media(max-width: 650px){
        width: 90%;

      }
      tr{
        width: 100%;
        &:nth-child(odd){
          background-color: #fff;
        }
        &:nth-child(even){

        }
        td{
          width: 50%;
          text-align: center;
          padding-bottom: 10px;
          padding-top: 10px;
          vertical-align: middle;
          &.showtimes{
            ul{
              li{
                display: inline-block;
                a{
                  display: inline-block;
                  background-color: $col-red;
                  padding: 10px;
                  margin-right: 5px;
                  margin-bottom: 5px;
                  border-radius: 10px;
                  color: #fff;
                  text-decoration: none;
                  transition: background-color 0.5s;
                  &:hover{
                    background-color: $col-dark;
                  }
                }
              }
            }
          }
        }
      }
    }
    #info{
      p{
        font-size: 12px;
        color: $col-black;
        text-align: center;
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
    #lat{
      display: none;
    }
  }

  footer{
  padding-top: 30px;
  padding-bottom: 30px;
  @media(max-width: 950px){
    padding-top: 0;
    padding-bottom: 60px;
  }
  ul{
    text-align: center;
    li{
      display: inline-block;
      margin-left: 20px;
      margin-right: 20px;
      @media(max-width: 400px){
        margin-left: 10px;
        margin-right: 10px;
      }
      @media(max-width: 350px){
        margin-bottom: 20px;
      }
      img{
        height: 60px;
        width: auto;
        @media(max-width: 1366px){
          height: 40px;
          width: auto;
        }

      }
    }
  }
}
}

.select2-results{
  .select2-results__option{
    &[aria-selected=true]{
      color: #fff;
      background-color: $col-red;
      &.select2-results__option--highlighted{
        background-color: $col-red;
      }
    }
    &.select2-results__option--highlighted{
      // &[aria-selected]{
      //   background-color: $col-red;
      // }
      background-color: $col-black;
    }
  }
}

#not-found{
  text-align: center;
  h1{
    font-size: 50px;
    margin-top: 40px;
  }
  p{
    font-family: sans-serif;
    margin-top: 20px;
    font-size: 12px;
    line-height: 18px;
  }
  .cbtn{
    display: block;
    color: #fff;
    background-color: $col-red;
    text-decoration: none;
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
    padding: 10px;
    margin-top: 50px;
    margin-bottom: 30px;
    &:hover{
      background-color: $col-black;
    }
  }
}

.cookies{
  .back{
    text-align: center;

    .cbtn{
      display: block;
      color: #fff;
      background-color: $col-red;
      text-decoration: none;
      max-width: 300px;
      margin-left: auto;
      margin-right: auto;
      padding: 10px;
      margin-top: 50px;
      margin-bottom: 30px;
      &:hover{
        background-color: $col-black;
      }
    }
  }

  h1{
    font-size: 30px;
    margin-top: 40px;
  }
  p{
    font-family: sans-serif;
    margin-top: 20px;
    font-size: 12px;
    line-height: 18px;
  }
  .links{
    text-align: center;
    .col-md-3{
      cursor: pointer;
      transition: opacity 0.5s;
      &:hover{
        opacity: 0.8;
      }
    }

    img{
      margin-top: 20px;
      margin-bottom: 20px;
    }
    a{
      display: block;
      color: $col-red;
      font-family: sans-serif;
      text-decoration: underline;
    }
  }
}

.animate-light{
  animation: opacityLightsIcon linear 4s;
  animation-iteration-count: infinite;
}



.cc-message{
  font-size: 12px;
}
