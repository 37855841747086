@keyframes arrow-slide{
  0% {
  top:0px;
  }
  25% {
  top:10px;
  }
  50% {
  top:0px;
  }
  100% {
  top:0px;
  }
}

@keyframes opacityLights{
  0% {
  opacity:1;
  }
  25% {
  opacity:0.6;
  }
  30% {
  opacity:1;
  }
  45% {
  opacity:0.6;
  }
  50% {
  opacity:1;
  }
  100% {
  opacity:1;
  }
}

@keyframes scaleBounce{
  0% {
  transform: scale(1,1);
  }

  50% {
  transform: scale(0.8,0.8);
  }
  100% {
  transform: scale(1,1);
  }
}

@keyframes opacityLoop{
  0% {
  opacity:0.5;
  }
  50% {
  opacity:1;
  }
  75% {
  opacity:1;
  }
  100% {
  opacity:0;
  }
}

@keyframes opacityLightsIcon{
  0% {
  opacity:0;
  }
  25% {
  opacity:1;
  }
  30% {
  opacity:0.8;
  }
  45% {
  opacity:1;
  }
  50% {
  opacity:0.7;
  }
  80% {
  opacity:1;
  }
  100% {
  opacity:0;
  }
}
